<template>
  <div>
    <CModal
      color="primary"
      :show="isShowScratch"
      :close-on-backdrop="false"
      :centered="true"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title white">Scratch Pad</h6>
        <CButtonClose @click="modalCallBack" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="newTodo"
          >Add</CButton
        >
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack"
          >Close</CButton
        >
      </template>
      <template #body-wrapper>
        <ScratchPad ref="scratchPad" :isFloatingScratch="true" />
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ScratchPad from "@/containers/Dashboard/RecruiterDashBoard/ScratchPad";
import { Role, getScope } from "@/helpers/helper";
export default {
  props: {
    isShowScratch: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ScratchPad,
  },
  methods: {
    modalCallBack() {
      this.$emit("scratchModalCallBack");
    },
    newTodo() {
      this.$refs.scratchPad.newTodo();
    },
  },
};
</script>
