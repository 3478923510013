<template>
  <div>
    <CModal
      color="primary"
      :show="isShowPopup"
      :close-on-backdrop="false"
      :centered="true"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title white">Calendar</h6>
        <CButtonClose @click="modalCallBack" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack"
          >Close</CButton
        >
      </template>
      <template #body-wrapper>
        <CalendarPad :isFloatingCalendar="true" :events="interviewEvents" />
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CalendarPad from "@/containers/Dashboard/RecruiterDashBoard/CalendarPad";
import { Role, getScope } from "@/helpers/helper";
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CalendarPad,
  },
  computed: {
    ...mapGetters(["getInterviewSlots"]),
    interviewEvents() {
      const t = this.getInterviewSlots.map((v) => {
        let start = new Date(v?.selected_slot_date);
        const startTimeSplit = v?.selected_slot_start.split(":");
        start.setHours(startTimeSplit[0], startTimeSplit[1], startTimeSplit[2]);

        let end = new Date(v?.selected_slot_date);
        const endTimeSplit = v?.selected_slot_end.split(":");
        end.setHours(endTimeSplit[0], endTimeSplit[1], endTimeSplit[2]);

        const title = v?.candidate?.full_name
          ? `Interview - ${v?.candidate?.full_name} | ${v?.job_display_uid} - ${v?.job?.job_title}`
          : "--";
        return {
          start,
          end,
          title,
          content: `Interviewer: ${v?.assigned_to_users_name} \n Job: ${v?.job?.job_title}-${v?.job_display_uid} \n <i class="v-icon material-icons">work</i>`,
          class: "leisure",
        };
      });
      return t;
    },
  },
  methods: {
    ...mapActions(["fetchInterviewSlot"]),
    modalCallBack() {
      this.$emit("calendarModalCallBack");
    },
  },
  mounted() {
    this.fetchInterviewSlot();
  },
};
</script>
