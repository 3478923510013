<template>
  <div class="scratch-pad position-relative">
    <CCard :class="{ 'mb-0 border-bottom-0 f-height': isFloatingScratch }" class="h-100">
      <CCardHeader v-if="!isFloatingScratch">
        <div
          class="
            card-header-title
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <h5 class="text-primary">Scratch Pad</h5>
          <div class="d-flex justify-content-start">
            <CButton
              shape="square"
              color="primary"
              class="fs-12"
              @click="newTodo()"
              >Add</CButton
            >
          </div>
        </div>
      </CCardHeader>
      <CCardBody class="scroll-area-lg p-0" v-if="getScratchPads.length">
        <div class="to-do-content">
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item"
              v-for="(data, index) in getScratchPads"
              :key="'todo' + index"
            >
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left mr-2">
                    <div class="custom-checkbox" color="primary">
                      <!-- <input type="checkbox" :id="'todo_input_'+index" class="custom-control-input" /> -->
                      <!-- <label :for="'todo_input_'+index" class="custom-control-label">&nbsp;</label> -->
                    </div>
                  </div>
                  <div class="widget-content-left">
                    <div class="widget-heading" v-html="data.title"></div>
                    <div class="widget-subheading text-muted">
                      <p class="mb-0">
                        <span v-html="data.description"></span>
                        <small class="opacity-6 ml-1">
                          <i class="fas fa-calendar" />
                          {{ dateFormat(data.date_added).time }}
                          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                          {{ dateFormat(data.date_added).date }}
                        </small>
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-flex flex-column justify-content-end widget-content-right widget-content-actions"
                  >
                  
                    <CBadge v-if="data.priority" class="status-colors" :color="priorityMethods(data).class" >{{toPascalCase(data.priority)}}</CBadge>
                    <div class="d-flex justify-content-center mt-2 w-10%">       
                    <CButton class="edit-icon"  @click="edit(data)">
                      <i class="fas fa-pencil-alt text-danger" />
                    </CButton>
                    <CButton
                      class="delete-icon"
                      @click="showDeleteConfirmation(data)"
                    >
                      <i class="fas fa-trash-alt text-danger" />
                    </CButton>
                    </div>
                  </div>
                </div>
              </div>
            
            </li>
          </ul>
        </div>
      </CCardBody>
      <CCardBody class="scroll-area-lg p-0" v-else>
        <ul class="list-group list-group-flush my-5" colspan="4">
          <li class="my-5">
            <div class="text-center my-5">
              <h5 class="text-center">
                No Data Found
                <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
              </h5>
            </div>
          </li>
        </ul>
      </CCardBody>
    </CCard>
    <div
      class="
        cover
        position-absolute
        d-flex
        justify-content-center
        align-items-center
      "
      v-if="isCandidateSupplierFromAccessToken"
    >
      <h5 class="text-center text-danger mb-4 p-3 border border-danger rounded-lg font-weight-bold">Full TalentFind License Required<span class="d-block">contact@talentfindsolutions.com</span></h5>
    </div>
    <ScratchPadModal
      v-if="currentTodo"
      :todo="currentTodo"
      :isShowPopup="isShowPopup"
      @modalCallBack="scratchModalCallBack"
      @submit="save"
    >
    </ScratchPadModal>
    <CModal
      color="primary"
      :show.sync="delPopup"
      :close-on-backdrop="false"
      :centered="true"
      size="sm"
      v-if="delPopup"
    >
      <template #header>
        <h6 class="modal-title">Delete Preferences</h6>
        <CButtonClose @click="scratchModalCallBack" class="text-black" />
      </template>
      <template #footer>
        <CButton
          form="reasons"
          color="secondary"
          @click="hideDeleteConfirmation()"
          >Cancel</CButton
        >
        <CButton
          form="reasons"
          color="primary"
          type="Submit"
          @click="deleteTodo(delTodo)"
          >Delete</CButton
        >
      </template>
      <div>
        <h6>
          Are you sure to delete <br /><span v-html="delTodo.title">{{
            delTodo ? delTodo.title : ""
          }}</span
          >?
        </h6>
      </div>
    </CModal>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import ScratchPadModal from "@/containers/Dashboard/RecruiterDashBoard/ScratchPadModal";

export default {
  props: {
    isFloatingScratch: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ScratchPadModal,
  },
  data() {
    return {
      todo: {},
      isShowPopup: false,
      delPopup: false,
      currentTodo: null,
      delTodo: null,
    };
  },
  computed: {
    ...mapGetters(["getScratchPads", "getScratchPad","isCandidateSupplierFromAccessToken"]),
  },
  mounted() {
    this.$store.dispatch("getScratchPadItems");
  },
  methods: {
    dateFormat(date) {
      return {
        date: moment.utc(date).local().format("DD-MM-YYYY"),
        time: moment.utc(date).local().format("hh:mm A"),
      };
    },
    newTodo() {
      this.currentTodo = { title: "", description: "" };
      this.isShowPopup = true;
    },
    edit(todo) {
      this.currentTodo = todo;
      this.isShowPopup = true;
      this.$store.dispatch("setScratchPad", todo);
    },
    scratchModalCallBack(action, data) {
      if (action === false) {
        this.isShowPopup = false;
        this.currentTodo = null;
      }
    },
    save(todo) {
      if (todo.id) {
        this.$store.dispatch("putScratchPad", todo);
      } else {
        this.$store
          .dispatch("postScratchPad", todo)
          .then(() => (this.currentTodo = null));
      }
      this.isShowPopup = false;
    },
    showDeleteConfirmation(item) {
      this.delTodo = item;
      this.delPopup = true;
    },
    hideDeleteConfirmation() {
      this.delPopup = false;
    },
    deleteTodo(item) {
      this.$store.dispatch("deleteScratchpad", item);
      this.delPopup = false;
    },
     priorityMethods(item) {
      switch (item?.priority) {
       case "low":
         return  { class: "success" };
        case "medium":
          return { class: "warning" };
        case "high":
          return { class: "danger" };
        default:
          return { class: "success" };
          
      }
    },
    toPascalCase(data) {
      if (data) return _.startCase(data);
      return data;
    },
  },
};
</script>
<style lang="scss" scoped>
$primary: #dd3651;

.fs-12 {
  font-size: 12px;
}
.scroll-area-lg {
  height: 350px;
  overflow-y: auto;
}
.list-group {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.widget-content {
  padding: 1rem;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
}
.widget-content .widget-content-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.widget-content .widget-content-left {
  font-weight: 600;
}
.widget-content .widget-content-right {
  margin-left: auto;
}
.hide {
  visibility: hidden;
}
h6 {
  line-height: 1.4rem;
}
.custom-checkbox {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background: $primary;
  padding: 0.25rem;
}
.f-height {
  min-height: 397px;
}

.status-colors {
  font: 11px Poppins, sans-serif;
  padding-top: 3px 10px;
  border-radius: 6px;
  color: #ffffff;
  min-width: 60px;
  text-align: center;
  max-height: 20px;
}

// .delete-icon {
//   display: none;
// }
// .edit-icon {
//   display: none;
// }
// .widget-content-wrapper:hover .delete-icon {
//   display: block;
// }
// .widget-content-wrapper:hover .edit-icon {
//   display: block;
// }

</style>
