<template>
  <div class="calendar-pad">
    <CCard :class="{ 'mb-0 border-bottom-0': isFloatingCalendar }">
      <CCardBody>
        <h5 v-if="!isFloatingCalendar" class="text-primary">Calendar</h5>
        <div class="calendar-wrapper">
          <vue-cal
            class="vuecal--primary-theme"
            :time="true"
            active-view="month"
            :events="events"
            :disable-views="['years', 'year']"
            :on-event-click="onEventClick"
            :time-step="15"
            :time-from="8 * 60"
            :time-to="18 * 60"
          />
        </div>
      </CCardBody>
    </CCard>
    <div
      class="
        cover
        position-absolute
        d-flex
        justify-content-center
        align-items-center
      "
      v-if="isCandidateSupplierFromAccessToken"
    >
      <h5 class="text-center text-danger mb-4 p-3 border border-danger rounded-lg font-weight-bold">Full TalentFind License Required<span class="d-block">contact@talentfindsolutions.com</span></h5>
    </div>
    <CModal
      class="text-black"
      :show.sync="showDialog"
      :close-on-backdrop="false"
      color="primary"
    >
      <template #header>
        <h6 class="modal-title">Test Title</h6>
        <CButtonClose @click="showDialog = false" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="showDialog = false"
          >Close</CButton
        >
      </template>
      <p>Testing event</p>
    </CModal>
  </div>
</template>
<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import { mapGetters } from 'vuex';
export default {
  props: {
    isFloatingCalendar: {
      type: Boolean,
      default: false,
    },
    events: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    VueCal,
  },
  data() {
    return {
      showDialog: false,
      selectedEvent: {},
      events1: [
        {
          start: "2020-09-21",
          end: "2020-09-21",
          title: "Need to go shopping",
          content: '<i class="fas fa-birthday-cake">shopping_cart</i>',
          class: "leisure",
        },
        {
          start: "2020-09-21",
          end: "2020-09-21",
          title: "Golf with John",
          content: '<i class="fas fa-user">golf_course</i>',
          class: "sport",
        },
        {
          start: "2020-09-22",
          end: "2020-09-22",
          title: "Dad's birthday!",
          content: '<i class="fas fa-birthday-cake">cake</i>',
          class: "sport",
        },
      ],
    };
  },
  computed:{
    ...mapGetters(["isCandidateSupplierFromAccessToken"])
  },
  methods: {
    onEventClick(event, e) {
      this.selectedEvent = event;
      this.showDialog = false;
      e.stopPropagation();
    },
  },
};
</script>
<style lang="scss" scoped>
.calendar-pad {
  .calendar-wrapper {
    height: 340px;
  }
}
</style>
