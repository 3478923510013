<template>
  <div class="scratch-pad">
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      v-if="todosData"
    >
      <template #header>
        <h6 class="modal-title" v-if="todosData && todosData.id">
          Edit Preferences
        </h6>
        <h6 class="modal-title" v-else>Add Scratch Pad Item</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()"
          >Save</CButton
        >
      </template>
      <div v-if="todosData">
        <ValidationObserver ref="scratchPad" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" name="scratchPad">
            <div class="pb-2">
              <label class="required">Title</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <TextInput
                  name="title"
                  :value="todosData.title"
                  @change="handleInput"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
            <div class="pb-2">
              <label class="required">Priority</label>
              <div>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <Select
                    name="priority"
                    :value="todosData.priority"
                    :options="
                      options && options['priority'] ? options['priority'] : []
                    "
                    @change="handleInput"
                    :taggable="false"
                    :multiple="false"
                    :clearable="false"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>

            <div class="pb-2">
              <label class="position-absolute">Description</label>
              <div class="editor-container">
                <vue-editor
                  class="message-body"
                  name="description"
                  ref="editor"
                  :value="todosData.description"
                  :text-change="textChange"
                  :editor-toolbar="customToolbar"
                  @input="(value) => handleEditor('description', value)"
                ></vue-editor>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapGetters, mapActions } from "vuex";
import Select from "@/components/reusable/Fields/Select";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";

extend("required", { ...required, message: "This field is required" });

export default {
  props: {
    todo: {
      type: Object,
      default: () => {},
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextInput,
    TextareaInput,
    Select,
  },
  data() {
    return {
      todosData: null,
      customToolbar: [
        ["link"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // ['blockquote', 'code-block'],
        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction
        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        // [{ 'font': [] }],                                 // font
        // ['clean']                                         // remove formatting button
      ],
      textChange: "source",
    };
  },
  computed: {
    ...mapGetters(["getCustomerId", "getUserId"]),
    options() {
      return {
        priority: [
          { label: "Low", code: "low" },
          { label: "Medium", code: "medium" },
          { label: "High", code: "high" },
        ],
      };
    },
  },
  watch: {
    todo() {
      this.cloneTodo();
    },
  },
  methods: {
    ...mapActions(["showToast"]),
    handleEditor(name, data) {
      Vue.set(this.todosData, name, data);
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },

    async onSubmit() {
      this.$refs.scratchPad.validate().then((success) => {
        if (!success) {
          this.showToast({
            class: "bg-danger text-white",
            message: "please provide an input!",
          });
          return;
        }
        const payload = {
          title: this.todosData.title,
          description: this.todosData.description,
          customer_uid: this.getCustomerId,
          user_id: this.getUserId,
          id: this.todosData.id,
          priority: (this.todosData?.priority?.label).toLowerCase() || null,
        };
        this.$emit("submit", payload);
        this.modalCallBack(true, payload);
      });
    },
    toPascalCase(todosData) {
      if (todosData) return _.startCase(todosData);
      return todosData;
    },
    handleInput(name, value) {
      Vue.set(this.todosData, name, value);
    },

    cloneTodo() {
      this.todosData = { ...this.todo };
      if (this.todo?.priority)
        this.todosData = {
          ...this.todosData,
          priority: {
            code: this.todo?.priority,
            label: this.toPascalCase(this.todo?.priority),
            title: null,
            description: null,
          },
        };
    },
  },
  mounted() {
    this.cloneTodo();
  },
};
</script>
