<template>
  <div class="pl-2 bg-course">
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowPopup"
      :closeOnBackdrop="false">
      <template #header>
        <h6>{{ modalTitle }}</h6>
        <CButtonClose @click="closeModal" />
      </template>

      <template #footer>
        <CButton form="course_modal" color="secondary" @click="closeModal"
          >Cancel</CButton
        >
        <CButton form="course_modal" :color="modalColor" type="Submit"
          >Submit</CButton
        >
      </template>

      <div>
        <ValidationObserver ref="course_modal" v-slot="{ handleSubmit }">
          <form id="course_modal" @submit.prevent="handleSubmit(onSubmit)">
            <CRow class="row mb-2">
              <label class="required ml-3">Enter Your Name</label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <TextInput
                    name="name"
                    :value="course_candidate.name"
                    @input="handleInput"
                    :error="errors[0]" />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-2">
              <label class="required ml-3">Enter your Email</label>
              <div class="col-12">
                <ValidationProvider rules="required|email" v-slot="{ errors }">
                  <TextInput
                    name="email"
                    :value="course_candidate.email"
                    @input="handleInput"
                    :error="errors[0]" />
                </ValidationProvider>
              </div>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";
import TextInput from "@/components/reusable/Fields/TextInput";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });

export default {
  name: "CourseModal",
  props: {
    modalTitle: String,
    modalColor: String,
    isShowPopup: Boolean,
    closeOnBackdrop: Boolean,
    size: String,
    courseModalCallBack: Function,
  },
  components: {
    TextInput,
  },
  data() {
    return {
      course_candidate: {},
      payload: {},
    };
  },
  methods: {
    ...mapActions(["createInterestedCourseCandidate"]),
    handleInput(name, value) {
      Vue.set(this.course_candidate, name, value);
      this.payload = {
        ...this.payload,
        [name]: value,
      };
    },
    closeModal() {
      this.$emit("courseModalCallBack",false);
    },
    async onSubmit() {
      const isValid = await this.$refs.course_modal.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.createInterestedCourseCandidate(this.payload)
      .then((res) =>{
        this.$emit("courseModalCallBack",true);
      })
      .catch((error) => {
          this.$emit("courseModalCallBack",false);
          console.error("Error creating interested course candidate:", error);
        });
    },
  },
};
</script>
