<template>
  <div class="candidate-notification d-flex align-items-center responsive">
    <div
      class="d-flex flex-row align-items-center"
      :class="mobile ? 'float-left' : 'mr-4 headerIconHolder'"
      @click="isShowNotification = !isShowNotification"
    >
      <div class="text-secondary mr-5 cursor-pointer">
        <i
          class="fa-solid fa-pen"
          style="font-size: large"
          @click="openReportIssueModal()"
        ></i>
      </div>
      <div class="notification-bell cursor-pointer">
        <span>
          <i class="fas fa-bell bell-icon"></i>
        </span>
        <span class="badge badge-md badge-circle badge-count text-white">
          {{ totalCount }}
        </span>
      </div>
    </div>

    <div
      v-if="isShowNotification && orgNotificationList.length"
      class="notification"
      :class="{ 'm-notification': mobile }"
    >
      <div class="modal-primary drop-down">
        <div class="header modal-header" :class="{ arrow: !mobile }">
          <h6 class="heading mb-0">Registered Organisations</h6>
        </div>
        <div class="content modal-content">
          <ul class="list">
            <li
              v-for="(data, index) in orgNotificationList"
              :key="index"
              class="notify p-2"
            >
              <div class="alert-message" @click="switchOrg(data)">
                <div class="message d-flex">
                  <div class="text pl-3 cursor-pointer">
                    <CBadge class="m-auto mr-1" color="primary" shape="pill">{{
                      data.notification_count
                    }}</CBadge>
                    {{ data.organisation_name || data.customer_name }}
                    {{
                      data.notification_count > 1
                        ? "notifications"
                        : "notification"
                    }}
                      <CBadge v-if="data.is_master" class="m-auto ml-2 mr-1" color="primary" shape="pill">
                      Master
                    </CBadge>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            class="footer-btn"
            @click="isShowNotification = !isShowNotification"
          >
            Close
          </button>
        </div>
      </div>
    </div>
    <ReportIssuePage
      v-if="reportIssueModal.isShowPopup"
      :isShowPopup="reportIssueModal.isShowPopup"
      :modalTitle="reportIssueModal.modalTitle"
      :modalCallBack="reportIssueModalCallBack"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReportIssuePage from "../../../pages/ReportIssuePage.vue";
import _ from "lodash";
export default {
  components: {
    ReportIssuePage,
  },
  name: "CandidateNotification",
  data() {
    return {
      isShowNotification: false,
      reportIssueModal: {
        isShowPopup: false,
        modalTitle: "Report a Problem",
      },
    };
  },
  computed: {
    ...mapGetters([
      "getWindowWidth",
      "getCandidateOrganisation",
      "getOrgIDFromAccessToken",
      "getCustomerId",
    ]),
    mobile() {
      return this.getWindowWidth <= 450 ? true : false;
    },
    totalCount() {
      return _.sumBy(
        this.orgNotificationList,
        ({ notification_count }) => notification_count
      );
    },
    orgNotificationList() {
      return this.getCandidateOrganisation
        .filter((v) =>
          this.getOrgIDFromAccessToken
            ? v.organisation_id != this.getOrgIDFromAccessToken
            : v.customer_uid != this.getCustomerId
        )
        ?.sort((a, b) => b.notification_count - a.notification_count);
    },
  },
  methods: {
    ...mapActions([
      "fetchCandidateOrganisation",
      "switchCandidateOrg",
      "fetchOrgDetailsByAcronym",
      "fetchAppcontent",
    ]),
    switchOrg(data) {
      const { path_acronym } = data;
      this.switchCandidateOrg(path_acronym).then(async (res) => {
        await this.fetchOrgDetailsByAcronym(path_acronym);
        this.fetchAppcontent();
        this.$router.push({ path: "/home" });
      });
    },
    reportIssueModalCallBack() {
      this.reportIssueModal.isShowPopup = false;
    },
    openReportIssueModal() {
      this.reportIssueModal.isShowPopup = true;
    },
  },
  mounted() {
    this.fetchCandidateOrganisation();
  },
};
</script>

<style lang="scss" scoped>
.m-notification {
  min-width: 240px;
  top: 64px !important;
  right: 20% !important;
}

.show {
  opacity: 0 !important;
}

.notification {
  position: absolute;
  top: 55px;
  right: 40%;
  width: 300px;
  padding: 10px;
  overflow: visible;
  z-index: 1050;

  .drop-down {
    width: 100%;
    box-shadow: 0 2px 4px 0 #0003, 0 2px 4px 0 #0003, inset 0 0 0 1px #0003;
    border-radius: 5px;

    .arrow {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -1px;
        left: 140px;
        height: 25px;
        width: 20px;
        background-color: inherit;
        transform: rotate(45deg);
      }
    }

    .modal-content {
      border: none;
      border-radius: 0;
    }

    .content {
      max-height: 380px;
      overflow-y: auto;
      overflow-x: hidden;

      ul {
        padding: 0;
      }

      .list {
        background: $white;
        color: black;

        .label {
          font-size: 12px;
          font-weight: 500;
          color: #6e7f8e;
        }

        .notify {
          border-bottom: 1px solid #f3f3f3;
          width: 100%;

          .icon {
            position: relative;
            top: 2px;
            max-width: 36px;
            max-height: 36px;
            margin: 7px;
            padding: 7px;
            border: 1px solid #e2e2e2;
            border-radius: 22px;
            background-color: #faebd7;

            .alt {
              position: relative;
              top: -3px;
            }

            .jobalert {
              position: relative;
              top: -4px;
              left: -1px;
            }

            .useralert {
              position: relative;
              top: -3px;
              left: -3px;
            }
          }

          .message {
            width: 90%;

            .text {
              color: #768192;
              font-size: 11px;
            }

            .date {
              font-size: 8px;
              color: #768192;
            }
          }
        }
      }
    }

    .modal-footer {
      background: #f9f8fd;
      border: none;
      padding: 0;

      .footer-btn {
        width: 100%;
        background: #f9f8fd;
        outline: none;
        border: none;
        padding: 5px;
        color: #747f8b;
      }
    }
  }
}

.alert-message,
.label {
  width: 100%;
}

.message,
.read {
  display: inline-block;
}

.read {
  float: right;
  position: relative;
  top: 0;
  transition: 0.5s ease;
  opacity: 0;

  i {
    font-size: 1em;
    color: #dd3651;
  }
}

li:hover .read {
  opacity: 1;
}

.clear {
  font-size: 12px;
}

.dot {
  height: 2px;
  width: 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: -1px;
}
</style>
