<template>
  <div class="alert-modal">
    <CModal
      class="text-black alert-main-modal"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      color="primary"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title white">Alert</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
      </template>
      <CTabs variant="tabs" :activeTab="0" v-if="isAlert && isAlert.length">
        <CTab
          :title="`Job Approval (${getJobApproval.length})`"
          v-if="getJobApproval.length"
        >
          <CCardBody class="p-0">
            <CDataTable
              :items="getJobApproval"
              :fields="jobApprovalFields"
              :items-per-page="10"
              hover
              pagination
            >
              <template #job_id="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToJob(item.job_id)"
                    >{{ item.job_display_uid }}</span
                  >
                </td>
              </template>
              <template #days_for_approval="{ item }">
                <td>
                  <span class="cursor-pointer">{{
                    daysLeft(item.days_for_approval) + days
                  }}</span>
                </td>
              </template>
              <template #action="{ item }">
                <td>
                  <CButton
                    class="pt-0 pb-0 text-primary"
                    @click="isShowScratchpad(item)"
                    title="Add to ScratchPad"
                    :disabled="isDisable(item.action_detail_id)"
                    ><i class="fa-solid fa-circle-plus fs-14"></i
                  ></CButton>
                </td>
              </template>
              <template #no-items-view>
                <ul class="list-group list-group-flush" colspan="4">
                  <li class="my-5 list-style-none">
                    <div class="text-center my-5">
                      <h5 class="text-center" :style="'color:'">
                        No Data Found
                        <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                      </h5>
                    </div>
                  </li>
                </ul>
              </template>
            </CDataTable>
          </CCardBody>
        </CTab>
        <CTab
          :title="`Job Expiry (${getJobExpiry.length})`"
          v-if="getJobExpiry.length"
        >
          <CCardBody class="p-0">
            <CDataTable
              :items="getJobExpiry"
              :fields="jobExpiryFields"
              :items-per-page="10"
              hover
              pagination
            >
              <template #job_id="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToJob(item.job_id)"
                    >{{ item.job_display_uid }}</span
                  >
                </td>
              </template>
              <template #days_for_expiry="{ item }">
                <td>
                  <span class="cursor-pointer">{{
                    daysLeft(item.days_for_expiry) + days
                  }}</span>
                </td>
              </template>
              <template #action="{ item }">
                <td>
                  <CButton
                    class="pt-0 pb-0 text-primary"
                    @click="isShowScratchpad(item)"
                    title="Add to ScratchPad"
                    :disabled="isDisable(item.job_id)"
                    ><i class="fa-solid fa-circle-plus fs-14"></i
                  ></CButton>
                </td>
              </template>
              <template #no-items-view>
                <ul class="list-group list-group-flush" colspan="4">
                  <li class="my-5 list-style-none">
                    <div class="text-center my-5">
                      <h5 class="text-center" :style="'color:'">
                        No Data Found
                        <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                      </h5>
                    </div>
                  </li>
                </ul>
              </template>
            </CDataTable>
          </CCardBody>
        </CTab>
        <CTab
          :title="`Submission Status (${getSubmisionStatus.length})`"
          v-if="getSubmisionStatus.length"
        >
          <CCardBody class="p-0">
            <CDataTable
              :items="getSubmisionStatus"
              :fields="submissionStatusField"
              :items-per-page="10"
              hover
              pagination
            >
              <template #candidate_id="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToCandidate(item.candidate_id)"
                    >{{ item.candidate_display_uid }}</span
                  >
                </td>
              </template>
              <template #job_id="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToJob(item.job_id)"
                    >{{ item.job_display_uid }}</span
                  >
                </td>
              </template>
              <template #days_for_approval="{ item }">
                <td>
                  <span class="cursor-pointer">{{
                    daysLeft(item.days_for_approval) + days
                  }}</span>
                </td>
              </template>
              <template #action="{ item }">
                <td>
                  <CButton
                    class="pt-0 pb-0 text-primary"
                    @click="isShowScratchpad(item)"
                    title="Add to ScratchPad"
                    :disabled="isDisable(item.action_detail_id)"
                    ><i class="fa-solid fa-circle-plus fs-14"></i
                  ></CButton>
                </td>
              </template>
              <template #no-items-view>
                <ul class="list-group list-group-flush" colspan="4">
                  <li class="my-5 list-style-none">
                    <div class="text-center my-5">
                      <h5 class="text-center" :style="'color:'">
                        No Data Found
                        <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                      </h5>
                    </div>
                  </li>
                </ul>
              </template>
            </CDataTable>
          </CCardBody>
        </CTab>
        <CTab
          :title="`Scheduled Status (${getScheduleStatus.length})`"
          v-if="getScheduleStatus.length"
        >
          <CCardBody class="p-0">
            <CDataTable
              :items="getScheduleStatus"
              :fields="interviewStatusField"
              :items-per-page="10"
              hover
              pagination
            >
              <template #candidate_id="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToCandidate(item.candidate_id)"
                    >{{ item.candidate_display_uid }}</span
                  >
                </td>
              </template>
              <template #job_id="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToJob(item.job_id)"
                    >{{ item.job_display_uid }}</span
                  >
                </td>
              </template>
              <template #days_for_approval="{ item }">
                <td>
                  <span class="cursor-pointer">{{
                    daysLeft(item.days_for_approval) + days
                  }}</span>
                </td>
              </template>
              <template #no-items-view>
                <ul class="list-group list-group-flush" colspan="4">
                  <li class="my-5 list-style-none">
                    <div class="text-center my-5">
                      <h5 class="text-center">
                        No Data Found
                        <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                      </h5>
                    </div>
                  </li>
                </ul>
              </template>
            </CDataTable>
          </CCardBody>
        </CTab>
        <CTab
          :title="`Interview Status (${getInterviewStatus.length})`"
          v-if="getInterviewStatus.length"
        >
          <CCardBody class="p-0">
            <CDataTable
              :items="getInterviewStatus"
              :fields="interviewStatusField"
              :items-per-page="10"
              hover
              pagination
            >
              <template #candidate_id="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToCandidate(item.candidate_id)"
                    >{{ item.candidate_display_uid }}</span
                  >
                </td>
              </template>
              <template #job_id="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToJob(item.job_id)"
                    >{{ item.job_display_uid }}</span
                  >
                </td>
              </template>
              <template #days_for_approval="{ item }">
                <td>
                  <span class="cursor-pointer">{{
                    daysLeft(item.days_for_approval) + days
                  }}</span>
                </td>
              </template>
              <template #no-items-view>
                <ul class="list-group list-group-flush" colspan="4">
                  <li class="my-5 list-style-none">
                    <div class="text-center my-5">
                      <h5 class="text-center">
                        No Data Found
                        <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                      </h5>
                    </div>
                  </li>
                </ul>
              </template>
            </CDataTable>
          </CCardBody>
        </CTab>
        <CTab :title="`Offer (${getOffer.length})`" v-if="getOffer.length">
          <CCardBody class="p-0">
            <CDataTable
              :items="getOffer"
              :fields="offerField"
              :items-per-page="10"
              hover
              pagination
            >
              <template #candidate_id="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToCandidate(item.candidate_id)"
                    >{{ item.candidate_display_uid }}</span
                  >
                </td>
              </template>
              <template #job_id="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToJob(item.job_id)"
                    >{{ item.job_display_uid }}</span
                  >
                </td>
              </template>
              <template #days_for_approval="{ item }">
                <td>
                  <span class="cursor-pointer">{{
                    daysLeft(item.days_for_approval) + days
                  }}</span>
                </td>
              </template>
              <template #no-items-view>
                <ul class="list-group list-group-flush" colspan="4">
                  <li class="my-5">
                    <div class="text-center my-5">
                      <h5 class="text-center">
                        No Data Found
                        <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                      </h5>
                    </div>
                  </li>
                </ul>
              </template>
            </CDataTable>
          </CCardBody>
        </CTab>
        <CTab
          :title="`Offer Accepted(${getOfferAccepted.length})`"
          v-if="getOfferAccepted.length"
        >
          <CCardBody class="p-0">
            <CDataTable
              :items="getOfferAccepted"
              :fields="offerAcceptedField"
              :items-per-page="10"
              hover
              pagination
            >
              <template #candidate_id="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToCandidate(item.candidate_id)"
                    >{{ item.candidate_display_uid }}</span
                  >
                </td>
              </template>
              <template #job_id="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToJob(item.job_id)"
                    >{{ item.job_display_uid }}</span
                  >
                </td>
              </template>
              <template #action="{ item }">
                <td>
                  <CButton
                    class="pt-0 pb-0 text-primary"
                    @click="isShowScratchpad(item)"
                    title="Add to ScratchPad"
                    :disabled="isDisable(item.action_detail_id)"
                    ><i class="fa-solid fa-circle-plus fs-14"></i
                  ></CButton>
                </td>
              </template>
              <template #no-items-view>
                <ul class="list-group list-group-flush" colspan="4">
                  <li class="my-5 list-style-none">
                    <div class="text-center my-5">
                      <h5 class="text-center">
                        No Data Found
                        <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                      </h5>
                    </div>
                  </li>
                </ul>
              </template>
            </CDataTable>
          </CCardBody>
        </CTab>
        <CTab
          v-if="
            getNewJobActionDetails &&
            getNewJobActionDetails.length &&
            isUKMainAgency
          "
          :title="`New Job (${getNewJobActionDetails.length})`"
        >
          <CCardBody class="p-0">
            <CDataTable
              :items="getNewJobActionDetails"
              :fields="newJobField"
              :items-per-page="10"
              hover
              pagination
            >
              <template #job_id="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToJob(item.job_id)"
                    >{{ item.job_display_uid }}</span
                  >
                </td>
              </template>
              <template #days_for_approval="{ item }">
                <td>
                  <span class="cursor-pointer">{{
                    daysLeft(item.days_for_approval) + days
                  }}</span>
                </td>
              </template>
              <template #no-items-view>
                <ul class="list-group list-group-flush" colspan="4">
                  <li class="my-5 list-style-none">
                    <div class="text-center my-5">
                      <h5 class="text-center">
                        No Data Found
                        <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                      </h5>
                    </div>
                  </li>
                </ul>
              </template>
            </CDataTable>
          </CCardBody>
        </CTab>
        <CTab
          v-if="
            getAgencyCVRequestMainAgency &&
            getAgencyCVRequestMainAgency.length &&
            isUKMainAgency
          "
          :title="`Agency CV Requests (${getAgencyCVRequestMainAgency.length})`"
        >
          <CCardBody class="p-0">
            <CDataTable
              :items="getAgencyCVRequestMainAgency"
              :fields="agencyCvRequestMainAgency"
              :items-per-page="10"
              hover
              pagination
            >
              <template #candidate_display_uid="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToCandidate(item.candidate_id)"
                    >{{ item.candidate_display_uid }}</span
                  >
                </td>
              </template>
              <template #no-items-view>
                <ul class="list-group list-group-flush" colspan="4">
                  <li class="my-5 list-style-none">
                    <div class="text-center my-5">
                      <h5 class="text-center">
                        No Data Found
                        <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                      </h5>
                    </div>
                  </li>
                </ul>
              </template>
            </CDataTable>
          </CCardBody>
        </CTab>
        <CTab
          v-if="
            getAgencyCVRequestHospital &&
            getAgencyCVRequestHospital.length &&
            isJobSupplierFromAccessToken
          "
          :title="`Agency CV Requests (${getAgencyCVRequestHospital.length})`"
        >
          <CCardBody class="p-0">
            <CDataTable
              :items="getAgencyCVRequestHospital"
              :fields="agencyCvRequestHospital"
              :items-per-page="10"
              hover
              pagination
            >
              <template #candidate_display_uid="{ item }">
                <td>
                  <span
                    style="color: #2678ce; font-weight: 600"
                    class="cursor-pointer"
                    @click="navToCandidate(item.candidate_id)"
                    >{{ item.candidate_display_uid }}</span
                  >
                </td>
              </template>
              <template #date_uploaded="{ item }">
                <td>
                  <span class="cursor-pointer">{{
                    formatDate(item.date_uploaded)
                  }}</span>
                </td>
              </template>
              <template #no-items-view>
                <ul class="list-group list-group-flush" colspan="4">
                  <li class="my-5 list-style-none">
                    <div class="text-center my-5">
                      <h5 class="text-center">
                        No Data Found
                        <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                      </h5>
                    </div>
                  </li>
                </ul>
              </template>
            </CDataTable>
          </CCardBody>
        </CTab>
      </CTabs>
      <CCardBody class="p-0" v-else>
        <ul class="list-group list-group-flush no-repeat" colspan="4">
          <li class="my-5 list-style-none">
            <div class="text-center my-5">
              <h5 class="text-center">
                No Data Found
                <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
              </h5>
            </div>
          </li>
        </ul>
      </CCardBody>
    </CModal>
    <ScratchpadModal
      v-if="scratchpad.isShowPopup"
      :isShowPopup="scratchpad.isShowPopup"
      :modalColor="scratchpad.modalColor"
      :modalTitle="scratchpad.modalTitle"
      :modalContent="scratchpad.modalContent"
      :buttons="scratchpad.buttons"
      :size="scratchpad.size"
      :modalCallBack="scratchPadModalCallBack"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import ScratchpadModal from "@/components/reusable/Modal";
import moment from "moment";
export default {
  props: {
    isShowAlert: {
      type: Boolean,
      default: false,
    },
    isShowAlertPopup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ScratchpadModal,
  },
  data() {
    return {
      Role,
      currentUserRole: getScope(),
      flag: true,
      jobApprovalFields: [
        { key: "job_id", label: "Job ID", _style: "width: 10%" },
        { key: "job_title", label: "Job Tittle", _style: "width: 35%" },
        {
          key: "days_for_approval",
          label: "Days for Approval",
          _style: "width: 20%",
        },
        { key: "recruiter", label: "Recruiter", _style: "width: 20%" },
        { key: "action", label: "Action", _style: "width: 10%" },
      ],
      jobExpiryFields: [
        { key: "job_id", label: "Job ID", _style: "width: 10%" },
        { key: "org_name", label: "Org Name", _style: "width: 20%" },
        { key: "recruiter", label: "Recruiter", _style: "width: 20%" },
        { key: "job_title", label: "Job Tittle", _style: "width: 40%" },
        {
          key: "days_for_expiry",
          label: "Days for Expiry",
          _style: "width: 10%",
        },
        { key: "action", label: "Action", _style: "width: 10%" },
      ],
      submissionStatusField: [
        { key: "candidate_id", label: "Candidate ID", _style: "width: 10%" },
        {
          key: "candidate_name",
          label: "Candidate Name",
          _style: "width: 15%",
        },
        { key: "job_id", label: "Job ID", _style: "width: 10%" },
        { key: "job_title", label: "Job Title", _style: "width: 35%" },
        {
          key: "days_for_approval",
          label: "Days for Approval",
          _style: "width: 20%",
        },
        { key: "recruiter", label: "Recruiter", _style: "width: 10%" },
        { key: "action", label: "Action", _style: "width: 10%" },
      ],
      interviewStatusField: [
        { key: "candidate_id", label: "Candidate ID", _style: "width: 15%" },
        {
          key: "candidate_name",
          label: "Candidate Name",
          _style: "width: 20%",
        },
        { key: "job_id", label: "Job ID", _style: "width: 10%" },
        { key: "job_title", label: "Job Title", _style: "width: 40%" },
        { key: "recruiter", label: "Recruiter", _style: "width: 10%" },
        {
          key: "days_for_approval",
          label: "Days for Approval",
          _style: "width: 20%",
        },
      ],
      offerField: [
        { key: "candidate_id", label: "Candidate ID", _style: "width: 15%" },
        {
          key: "candidate_name",
          label: "Candidate Name",
          _style: "width: 20%",
        },
        { key: "job_id", label: "Job ID", _style: "width: 10%" },
        { key: "job_title", label: "Job Title", _style: "width: 40%" },
        { key: "recruiter", label: "Recruiter", _style: "width: 10%" },
        {
          key: "days_for_approval",
          label: "Days for Approval",
          _style: "width: 20%",
        },
      ],
      offerAcceptedField: [
        { key: "candidate_id", label: "Candidate ID", _style: "width: 15%" },
        {
          key: "candidate_name",
          label: "Candidate Name",
          _style: "width: 20%",
        },
        { key: "job_id", label: "Job ID", _style: "width: 10%" },
        { key: "job_title", label: "Job Title", _style: "width: 40%" },
        { key: "action", label: "Action", _style: "width: 10%" },
      ],
      newJobField: [
        { key: "org_name", label: "Organisation Name", _style: "width: 35%" },
        { key: "job_id", label: "Job ID", _style: "width: 10%" },
        { key: "job_title", label: "Job Title", _style: "width: 35%" },
        {
          key: "date_created",
          label: "Date Created",
          _style: "width: 25%",
        },
      ],
      agencyCvRequestMainAgency: [
        { key: "org_name", label: "NHS Hospital Name", _style: "width: 35%" },
        {
          key: "candidate_display_uid",
          label: "Candidate ID",
          _style: "width: 20%",
        },
        {
          key: "candidate_name",
          label: "Candidate Name",
          _style: "width: 30%",
        },
        {
          key: "date_requested",
          label: "Date of Request",
          _style: "width: 20%",
        },
      ],
      agencyCvRequestHospital: [
        {
          key: "candidate_display_uid",
          label: "Candidate ID",
          _style: "width: 30%",
        },
        {
          key: "candidate_name",
          label: "Candidate Name",
          _style: "width: 30%",
        },
        {
          key: "date_uploaded",
          label: "Date Uploaded",
          _style: "width: 30%",
        },
      ],
      days: " days",
      scratchpad: {
        isShowPopup: false,
        modalColor: "primary",
        modalTitle: "ScratchPad",
        modalContent: "Save item to your scratch pad?",
        buttons: ["No", "Yes"],
        size: "sm",
      },
      todoData: null,
    };
  },
  watch: {
    isAlert: function (value) {
      if (value?.length > 0) {
        this.$store.commit("SET_ALERT_MESSAGES", value);
      }
    },
  },
  computed: {
    ...mapGetters([
      "getSubmissionAction",
      "getInterviewRequestAction",
      "getInterviewScheduleAction",
      "getJobApprovalAction",
      "getJobsForDashboard",
      "getJobsForAccordion",
      "getCustomerId",
      "getUserId",
      "getScratchPads",
      "getOfferActionDetails",
      "getOnboardActionDetails",
      "getJobPendingAction",
      "isUKMainAgency",
      "getAgencyCVRequest",
      "isJobSupplierFromAccessToken",
      "getShowAlert",
    ]),
    getJobApprovalActionDetails() {
      return (
        this.getJobApprovalAction
          .filter((val) => {
            if (val?.completed_date === null && val?.completed_by === null) {
              return val;
            }
          })
          .map((v) => ({
            job_id: v?.job_id,
            job_display_uid: v?.job_display_uid,
            job_title: v?.job?.job_title,
            days_for_approval: this.formatDate(v?.expiry_date),
            action_detail_id: v?.action_detail_id,
            recruiter:
              v?.initiated_by_user?.first_name +
              " " +
              v?.initiated_by_user?.surname,
            title: `Job Approval Follow up - ${v?.job?.job_title}(ID-${v?.job_display_uid})`,
            description: `Please follow up with ${v?.assigned_to_users_name} for approval on <a href="/job-list-preview/${v?.job_id}">${v?.job?.job_title} |  ${v?.job_display_uid}</a>`,
          })) || []
      );
    },
    getJobApproval() {
      return this.getJobApprovalActionDetails.filter((value) => {
        return this.statusDaysLeft(value);
      });
    },
    getJobExpiryAction() {
      return (
        this.getJobsForDashboard.map((v) => ({
          job_id: v?.job_id,
          job_display_uid: v?.display_uid,
          job_title: v?.job_title,
          days_for_expiry: this.formatDate(v?.expiry_review_date),
          recruiter:
            v?.initiated_by_user?.first_name +
            " " +
            v?.initiated_by_user?.surname,
          org_name: v?.organisation_name,
          title: `Job Expiry Follow up - ${v?.job_title} | ${v?.display_uid}`,
          description: `Please follow up Job Expiry extension for <a href="/job-list-preview/${v?.job_id}">${v?.job_title} |  ${v?.display_uid}</a>`,
        })) || []
      );
    },
    getJobExpiry() {
      return this.getJobExpiryAction.filter((value) => {
        return this.jobDaysLeft(value);
      });
    },
    getInterviewRequestActionDetails() {
      return (
        this.getInterviewRequestAction
          .filter((val) => {
            if (val?.completed_date === null && val?.completed_by === null) {
              return val;
            }
          })
          .map((v) => ({
            candidate_id: v?.candidate_uid,
            candidate_display_uid: v?.candidate_display_uid,
            candidate_name: v?.candidate?.full_name,
            job_id: v?.job_id,
            job_display_uid: v?.job_display_uid,
            job_title: v?.job?.job_title,
            days_for_approval: this.formatDate(v?.expiry_date),
            recruiter:
              v?.initiated_by_user?.first_name +
              " " +
              v?.initiated_by_user?.surname,
          })) || []
      );
    },
    getSubmisionAction() {
      return (
        this.getSubmissionAction
          .filter((val) => {
            if (val?.completed_date === null && val?.completed_by === null) {
              return val;
            }
          })
          .map((v) => ({
            candidate_id: v?.candidate_uid,
            candidate_display_uid: v?.candidate_display_uid,
            candidate_name: v?.candidate?.full_name,
            job_id: v?.job_id,
            job_display_uid: v?.job_display_uid,
            job_title: v?.job?.job_title,
            days_for_approval: this.formatDate(v?.expiry_date),
            recruiter:
              v?.initiated_by_user?.first_name +
              " " +
              v?.initiated_by_user?.surname,
            title: `Submission status Follow up - ${v?.candidate?.full_name} | ${v?.candidate_display_uid} - ${v?.job?.job_title} | ${v?.job_display_uid}`,
            description: `Please follow up Submission for <a href='/candidate/${v?.candidate_uid}'> ${v?.candidate?.full_name} | ${v?.candidate_display_uid}</a> - <a href="/job-list-preview/${v?.job_id}">${v?.job?.job_title} | ${v?.job_display_uid}</a>`,
          })) || []
      );
    },
    getSubmisionStatus() {
      return this.getSubmisionAction.filter((value) => {
        return this.statusDaysLeft(value);
      });
    },
    getInterviewScheduleActionDetails() {
      return (
        this.getInterviewScheduleAction
          .filter((val) => {
            if (val?.completed_date === null && val?.completed_by === null) {
              return val;
            }
          })
          .map((v) => ({
            candidate_id: v?.candidate_uid,
            candidate_display_uid: v?.candidate_display_uid,
            candidate_name: v?.candidate?.full_name,
            job_id: v?.job_id,
            job_display_uid: v?.job_display_uid,
            job_title: v?.job?.job_title,
            days_for_approval: this.formatDate(v?.expiry_date),
            recruiter:
              v?.initiated_by_user?.first_name +
              " " +
              v?.initiated_by_user?.surname,
          })) || []
      );
    },
    getScheduleStatus() {
      return this.getInterviewRequestActionDetails.filter((value) => {
        return this.statusDaysLeft(value);
      });
    },
    getOffersActionDetails() {
      return (
        this.getOfferActionDetails
          .filter((val) => {
            if (val?.status_id === 58 && val?.sub_status_id === 59) {
              return val;
            }
          })
          .map((v) => ({
            candidate_id: v?.candidate_uid,
            candidate_display_uid: v?.candidate_display_uid,
            candidate_name: v?.candidate?.full_name,
            job_id: v?.job_id,
            job_display_uid: v?.job_display_uid,
            job_title: v?.job?.job_title,
            days_for_approval: this.formatDate(v?.expiry_date),
            recruiter:
              v?.initiated_by_user?.first_name +
              " " +
              v?.initiated_by_user?.surname,
          })) || []
      );
    },
    getOffer() {
      return this.getOffersActionDetails.filter((value) => {
        return this.statusDaysLeft(value);
      });
    },
    getOfferAccepted() {
      return (
        this.getOfferActionDetails
          .filter((value) => {
            if (value?.status_id === 58 && value?.sub_status_id === 60) {
              return value;
            }
          })
          .map((v) => ({
            candidate_id: v?.candidate_uid,
            candidate_display_uid: v?.candidate_display_uid,
            candidate_name: v?.candidate?.full_name,
            job_id: v?.job_id,
            action_detail_id: v?.action_detail_id,
            job_display_uid: v?.job_display_uid,
            job_title: v?.job?.job_title,
            days_for_approval: this.formatDate(v?.expiry_date),
            title: `Offer Accepted Follow up- ${v?.candidate?.full_name} | ${v?.candidate_display_uid} - ${v?.job?.job_title} | ${v?.job_display_uid}`,
            description: `Please follow up Offer Accepted for <a href='/candidate/${v?.candidate_uid}'> ${v?.candidate?.full_name} | ${v?.candidate_display_uid}</a> - <a href="/job-list-preview/${v?.job_id}">${v?.job?.job_title} | ${v?.job_display_uid}</a>`,
          })) || []
      );
    },
    getNewJobActionDetails() {
      return this.getJobPendingAction?.length
        ? this.getJobPendingAction
            ?.filter((val) => {
              if (
                val?.status_id === 28 &&
                val?.sub_status_id === 72 &&
                val?.initiated_by_user?.org_detail?.is_child
              ) {
                return val;
              }
            })
            .map((v) => ({
              org_name: v?.organisation_name,
              job_id: v?.job_id,
              job_display_uid: v?.job_display_uid,
              job_title: v?.job?.job_title,
              date_created: this.formatDate(v?.initiated_date),
            }))
        : [];
    },
    getAgencyCVRequestMainAgency() {
      return this.getAgencyCVRequest?.length
        ? this.getAgencyCVRequest
            ?.filter((val) => {
              if (val.date_uploaded == null && val.close == false) {
                return val;
              }
            })
            .map((v) => ({
              org_name: v?.org_name,
              candidate_id: v?.candidate_id,
              candidate_display_uid: v?.candidate_display_uid,
              candidate_name: v?.candidate_name,
              date_requested: this.formatDate(v?.date_requested),
            }))
        : [];
    },
    getAgencyCVRequestHospital() {
      return this.getAgencyCVRequest?.length
        ? this.getAgencyCVRequest
            ?.filter((val) => {
              if (val.date_uploaded && val.close == false) {
                return val;
              }
            })
            .map((v) => ({
              trans_id: v?.transaction_id,
              req_org_id: v?.req_org_id,
              request_user_id: v?.req_user_id,
              uploader_user_id: v?.uploader_user_id,
              org_name: v?.org_name,
              candidate_id: v?.candidate_id,
              candidate_display_uid: v?.candidate_display_uid,
              candidate_name: v?.candidate_name,
              date_requested: v?.date_requested,
              date_uploaded: v?.date_uploaded,
              close: v?.close,
            }))
        : [];
    },
    isAlert() {
      let alert = [];
      alert = [
        ...alert,
        ...this.getJobApproval,
        ...this.getJobExpiry,
        ...this.getSubmisionStatus,
        ...this.getScheduleStatus,
        ...this.getInterviewStatus,
        ...this.getOfferAccepted,
      ];
      if (this.isUKMainAgency) {
        alert = [
          ...alert,
          ...this.getNewJobActionDetails, // pertemps, show alert for main agency when partner hospital created a job
          ...this.getAgencyCVRequestMainAgency, // pertemps, show alert for main agency when hospital request agency cv
        ];
      }
      if (this.isJobSupplierFromAccessToken) {
        alert = [
          ...alert,
          ...this.getAgencyCVRequestHospital, // pertemps, show alert for hospital when agency submit agency cv
        ];
      }
      return alert;
    },
    getInterviewStatus() {
      return this.getInterviewScheduleActionDetails.filter((value) => {
        return this.statusDaysLeft(value);
      });
    },
    isShowPopup() {
      return (this.isAlert?.length &&
        this.isShowAlertPopup &&
        !this.scratchpad.isShowPopup) ||
        (this.isShowAlert && !this.scratchpad.isShowPopup)
        ? true
        : false;
    },
  },
  methods: {
    ...mapActions([
      "setCandidateListBackUrl",
      "resetCandidateDetail",
      "postScratchPad",
      "fetchSubmissionActionDetails",
      "fetchInterviewRequestAction",
      "getInterviewScheduledCandidateActionDetails",
      "fetchJobApprovalAction",
      "fetchJobsForDashboard",
      "getScratchPadItems",
      "fetchOfferActionDetails",
      "fetchOnBoardActionDetails",
      "getJobPendingActionDetails",
      "fetchAgencyTransaction",
      "updateAgencyTransaction",
    ]),
    modalCallBack(action) {
      this.$emit("alertModalCallBack");
    },
    formatDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    statusDaysLeft(data) {
      let approvalDate = this.daysLeft(data.days_for_approval);
      if (approvalDate <= 3 && approvalDate >= 0) {
        return data;
      }
    },
    jobDaysLeft(data) {
      let approvalDate = this.daysLeft(data.days_for_expiry);
      if (approvalDate <= 7 && approvalDate >= 0) {
        return data;
      }
    },
    daysLeft(date) {
      return moment(date).diff(moment().format("YYYY-MM-DD"), "days");
    },
    navToJob(job_id) {
      this.modalCallBack();
      this.$router.push(`/job-list-preview/${job_id}`);
    },
    navToCandidate(candidate_id) {
      this.modalCallBack();
      // this.resetCandidateDetail();
      this.$router.push({ path: `/candidate/${candidate_id}` });
    },
    scratchPadModalCallBack(action) {
      if (action == "Yes") {
        this.postScratchPad(this.todoData);
      }
      this.scratchpad.isShowPopup = false;
      this.todoData = null;
    },
    isShowScratchpad(item) {
      this.scratchpad.isShowPopup = true;
      this.todoData = {
        title: item?.title,
        description: item?.description,
        customer_uid: this.getCustomerId,
        user_id: this.getUserId,
        action_detail_id: item?.action_detail_id,
        job_id: item?.job_id,
      };
    },
    isDisable(id) {
      return this.getScratchPads.filter((item) => {
        if (item.action_detail_id === id || item.job_id === id) {
          return item;
        }
      })?.length
        ? true
        : false;
    },
  },
  mounted() {
    let appendAction = [];
    const { path } = this.$route;
    if (!this.getShowAlert && path != "/recruiter-dashboard") {
      appendAction = [
        ...appendAction,
        this.getScratchPadItems(),
        this.fetchSubmissionActionDetails(),
        this.fetchInterviewRequestAction(),
        this.fetchJobApprovalAction(),
        this.fetchJobsForDashboard(),
        this.getInterviewScheduledCandidateActionDetails(),
        this.fetchOfferActionDetails(),
      ];
    }
    appendAction = [...appendAction, this.getJobPendingActionDetails()];
    if (this.isJobSupplierFromAccessToken || this.isUKMainAgency)
      appendAction = [...appendAction, this.fetchAgencyTransaction()];
    Promise.all(appendAction);
  },
};
</script>
<style lang="scss">
.alert-main-modal {
  td,
  th {
    text-align: center !important;
  }
  .fs-14 {
    font-size: 14px !important;
  }
}
</style>
