<template>
  <div>
    <div
      class="notification"
      v-if="getNotificationAlerts.length"
      :class="{ 'm-notification': mobile }"
    >
      <div class="modal-primary drop-down">
        <div class="header modal-header" :class="{ arrow: !mobile }">
          <h6 class="heading mb-0">Notifications</h6>
          <div @click="clearAllNotification">
            <h6 class="mb-0 cursor-pointer clear">Clear All</h6>
          </div>
        </div>
        <div class="content modal-content">
          <ul
            class="list"
            v-for="(data, index) in groupedFilterData"
            :key="index"
          >
            <span class="notify p-2" v-if="data.value.length">
              <label class="label p-2 mb-0">{{ data.label }}</label>
            </span>
            <li
              v-for="(data, index_) in data.value"
              :key="index_"
              class="notify p-2"
            >
              <div class="alert-message">
                <div class="message">
                  <div
                    class="text pl-3 cursor-pointer"
                    @click="navigateTo(data)"
                  >
                    {{ content(data) }}
                  </div>
                  <div class="date pl-3 pt-1">
                    <span class="mr-1">{{ createdOn(data.created_on) }}</span
                    ><span v-if="data.alert_sub_type_id"
                      ><span class="dot"></span>
                      <span class="ml-1">{{
                        status(data.alert_sub_type_id)
                      }}</span></span
                    >
                  </div>
                </div>
                <div
                  class="read cursor-pointer"
                  @click="clearNotification(data, index, index_)"
                >
                  <span>
                    <i class="fas fa-times-circle"></i>
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer" v-if="true">
          <button class="footer-btn" @click="modalCallBack">Close</button>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show" @click="modalCallBack"></div>
    <Modal
      v-if="confirmModal.isShowPopup"
      :modalTitle="confirmModal.modalTitle"
      :modalColor="confirmModal.modalColor"
      :modalContent="confirmModal.modalContent"
      :isShowPopup="confirmModal.isShowPopup"
      :buttons="confirmModal.buttons"
      :modalCallBack="confirmModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { Role } from "@/helpers/helper";
import moment from "moment";
import Modal from "@/components/reusable/Modal";
export default {
  name: "Notification",
  components: {
    Modal,
  },
  data() {
    return {
      url: null,
      Role,
      alert_id: null,
      c_time: new Date().toISOString(),
      groupedItem: [
        { label: "Job Approval", code: 1, value: [], load_time: this.c_time },
        { label: "Job Expiry", code: 2, value: [], load_time: this.c_time },
        {
          label: "Submission Status",
          code: 3,
          value: [],
          load_time: this.c_time,
        },
        {
          label: "Schedule Status",
          code: 4,
          value: [],
          load_time: this.c_time,
        },
        {
          label: "Interview Status",
          code: 5,
          value: [],
          load_time: this.c_time,
        },
        { label: "Email", code: 6, value: [], load_time: this.c_time },
        { label: "Offer", code: 8, value: [], load_time: this.c_time },
        { label: "Onboard", code: 9, value: [], load_time: this.c_time },
        {
          label: "Job Allocation",
          code: 10,
          value: [],
          timeload_time: this.c_time,
        },
        { label: "Job Published", code: 11, value: [], load_time: this.c_time },
        { label: "Document", code: 12, value: [], load_time: this.c_time },
      ],
      flag: false,
      tabs: { 1: 0, 2: 1, 3: 3, 4: 3, 5: 4, 6: 5, 7: 6, 8: 5, 9: 6, 12: 2 },
      jcpAction: [1, 2, 3, 4, 5, 8, 9, 12],
      email: 6,
      confirmModal: {
        modalColor: "primary",
        modalTitle: "Confirmation",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
    };
  },
  computed: {
    ...mapGetters([
      "getWindowWidth",
      "getRoleScopes",
      "getNotificationAlerts",
      "getNotification",
      "isJobSupplierFromAccessToken",
      "isUKMainAgency",
    ]),
    currentUserRole() {
      const roleCheck = {
        customer_recruiter: "customer_recruiter",
        customer_admin: "customer_admin",
      };
      return this.getRoleScopes.length
        ? this.currentUserType?.code
          ? roleCheck[this.currentUserType?.code] || "customer_user"
          : this.getRoleScopes[0]
        : "";
    },
    isCustomerLogin() {
      return [this.Role.customerAdmin, this.Role.customerRecruiter].includes(
        this.currentUserRole
      );
    },
    isSystemLogin() {
      return [this.Role.systemAdmin, this.Role.systemRecruiter].includes(
        this.currentUserRole
      );
    },
    seeAllButton() {
      return this.notification.length > 25 ? true : false;
    },
    mobile() {
      return this.getWindowWidth <= 450 ? true : false;
    },
    groupedFilterData() {
      Array.prototype.insert = function (index) {
        this.splice.apply(
          this,
          [index, 0].concat(Array.prototype.slice.call(arguments, 1))
        );
        return this;
      };
      for (var i = 0; i < this.getNotificationAlerts.length; i++) {
        for (var j = 0; j < this.groupedItem.length; j++) {
          if (
            this.groupedItem[j].code ==
              this.getNotificationAlerts[i].alert_type_id &&
            !this.groupedItem[j]?.value.some(
              (e) =>
                JSON.stringify(e) ===
                JSON.stringify(this.getNotificationAlerts[i])
            )
          ) {
            this.groupedItem[j]?.value.insert(0, this.getNotificationAlerts[i]);
            this.groupedItem[j].load_time =
              this.getNotificationAlerts[i]?.created_on;
          }
        }
      }
      this.groupedItem =
        this.groupedItem && this.groupedItem?.length
          ? _.sortBy(this.groupedItem, (e) => {
              return e.load_time;
            }).reverse()
          : [];
      return this.groupedItem;
    },
  },
  methods: {
    ...mapActions([
      "fetchNotificationAlerts",
      "clearNotificationAlerts",
      "clearAllNotificationAlerts",
    ]),
    content(data) {
      switch (data.alert_type_id) {
        case 1:
          if (data.alert_sub_type_id == 29)
            return `${data.job_title} (${data.job_display_uid}) - job was approved`;
          else if (data.alert_sub_type_id == 73)
            return `${data.job_title} (${data.job_display_uid}) - job was hold`;
          else if (data.alert_sub_type_id == 74)
            return `${data.job_title} (${data.job_display_uid}) - job was disapproved`;
          else
            return `${data.job_title} (${data.job_display_uid}) - ${
              this.dateDiff(data.created_on, data.expiry_date) >= 0
                ? `${this.daysLeft(
                    this.dateDiff(data.created_on, data.expiry_date)
                  )} for approval`
                : this.daysLeft(
                    this.dateDiff(data.created_on, data.expiry_date)
                  )
            }`;
        case 2:
          return `${data.job_title} (${data.job_display_uid}) - ${
            this.dateDiff(data.created_on, data.expiry_date) >= 0
              ? `${this.daysLeft(
                  this.dateDiff(data.created_on, data.expiry_date)
                )} for expiry`
              : this.daysLeft(this.dateDiff(data.created_on, data.expiry_date))
          }`;
        case 4:
          if (data.alert_sub_type_id == 54)
            return `${data.candidate_name} (${data.candidate_display_uid}) - interview slot scheduled`;
          else
            return `${data.candidate_name} (${data.candidate_display_uid}) - ${
              this.dateDiff(data.created_on, data.expiry_date) >= 0
                ? `${this.daysLeft(
                    this.dateDiff(data.created_on, data.expiry_date)
                  )} for approval`
                : this.daysLeft(
                    this.dateDiff(data.created_on, data.expiry_date)
                  )
            } `;
        case 3:
          if (data.alert_sub_type_id == 50)
            return `${data.candidate_name} (${data.candidate_display_uid}) - scheduled for interview`;
          if (data.alert_sub_type_id == 44)
            return `${data.candidate_name} (${data.candidate_display_uid}) - hold for interview`;
          if (data.alert_sub_type_id == 47)
            return `${data.candidate_name} (${data.candidate_display_uid}) - disapproved for interview`;
          else
            return `${data.candidate_name} (${data.candidate_display_uid}) - ${
              this.dateDiff(data.created_on, data.expiry_date) >= 0
                ? `${this.daysLeft(
                    this.dateDiff(data.created_on, data.expiry_date)
                  )} for approval`
                : this.daysLeft(
                    this.dateDiff(data.created_on, data.expiry_date)
                  )
            } `;
        case 5:
          if (data.alert_sub_type_id == 57)
            return `${data.candidate_name} (${data.candidate_display_uid}) - interview completed`;
          else
            return `${data.candidate_name} (${data.candidate_display_uid}) - ${
              this.dateDiff(data.created_on, data.expiry_date) >= 0
                ? `${this.daysLeft(
                    this.dateDiff(data.created_on, data.expiry_date)
                  )} for approval`
                : this.daysLeft(
                    this.dateDiff(data.created_on, data.expiry_date)
                  )
            }`;
        case 6:
          return `Email received from ${data.email}`;
        case 8:
          if (data.alert_sub_type_id == 60)
            return `${data.candidate_name} (${data.candidate_display_uid}) - offer accepted`;
        case 9:
          if (data.alert_sub_type_id == 69)
            return `${data.candidate_name} (${data.candidate_display_uid}) - onboard docs submitted`;
        case 10:
          return `${data.job_title} (${data.job_display_uid}) - new job allocation`;
        case 11:
          return `${data.job_title} (${data.job_display_uid}) - job published`;
        case 12:
          if (data.alert_sub_type_id == 6)
            return `Agency CV Request Received for Candidate (${data.candidate_display_uid}) ${data.candidate_name}`;
          if (data.alert_sub_type_id == 52)
            return `Agency CV Request fulfilled for Candidate (${data.candidate_display_uid}) ${data.candidate_name}`;
        default:
          break;
      }
    },
    status(id) {
      switch (id) {
        case 57:
          return "Interview Complete";
        case 50:
        case 29:
          return "Approved";
        case 44:
        case 73:
          return "Hold/Query";
        case 47:
        case 74:
          return "Disapproved";
        case 54:
          return "Interview Accepted";
        case 60:
          return "Offer";
        case 60:
          return "Onboard";
        case 6:
        case 52:
          return "Document";
        default:
          return "Expiry";
      }
    },
    daysLeft(days) {
      if (days > 0) return `${days} day(s) left`;
      if (days == 0) return "last day";
      if (days < 0) return `expired ${Math.abs(days)} day(s) ago`;
    },
    createdOn(data) {
      return moment.utc(data).fromNow();
    },
    dateDiff(created, expiry) {
      let expiry_date = moment.utc(expiry);
      let created_date = moment.utc(created);
      return expiry_date.diff(created_date, "days");
    },
    modalCallBack() {
      this.$emit("notifiModalCallBack");
    },
    clearNotification(data, index, index_) {
      this.groupedItem[index].value.splice(index_, 1);
      let payload = {
        alerts_to: [],
      };
      payload.alerts_to.push(data.alert_id);
      this.clearNotificationAlerts(payload);
    },
    clearAllNotification() {
      this.confirmModal.modalContent =
        "Are you sure you would like to clear all notifications?";
      this.confirmModal.isShowPopup = true;
    },
    notificationType(list, id) {
      return _.find(list, (e) => e.code == id);
    },
    navigateTo(data) {
      if (data.alert_type_id == this.email) {
        this.$router.push("/email-messages#inbox");
      } else if (this.jcpAction.includes(data.alert_type_id)) {
        if (this.$router.currentRoute.hash == "#jcp") return;
        this.$router
          .push({
            path: "/recruiter-dashboard",
            query: { tab: `${this.getJcpTab(data.alert_type_id)}` },
            hash: `#jcp`,
          })
          .then((res) => {
            const hash = location.hash.substr(1);
            this.$nextTick(() => {
              document.getElementById(hash).scrollIntoView();
            });
            this.$router.push({
              query: { tab: `${this.getJcpTab(data.alert_type_id)}` },
              hash: null,
            });
          });
      }
      // close dropdown
      this.$emit("notifiModalCallBack");
    },
    getJcpTab(val) {
      let value = val;
      if (
        (this.isJobSupplierFromAccessToken || this.isUKMainAgency) &&
        value > 2 &&
        value != 12
      ) {
        if (!this.isJobSupplierFromAccessToken) value += 1;
        if (this.isUKMainAgency) value += 1;
      }
      return _.get(this.tabs, value);
    },
    confirmModalCallBack(action) {
      const confirmClear = "Confirm";
      if (action === confirmClear) {
        let payload = {
          alerts_to: [],
        };
        for (let i = 0; i < this.getNotificationAlerts.length; i++) {
          payload.alerts_to.push(this.getNotificationAlerts[i].alert_id);
        }
        this.clearAllNotificationAlerts(payload);
        this.modalCallBack();
      }
      this.confirmModal.isShowPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.m-notification {
  width: 100% !important;
  top: 64px !important;
  left: 0 !important;
  right: 0 !important;
}

.show {
  opacity: 0 !important;
}

.notification {
  position: absolute;
  top: 55px;
  right: -163px;
  width: 400px;
  padding: 10px;
  overflow: visible;
  z-index: 1050;

  .drop-down {
    width: 100%;
    box-shadow: 0 2px 4px 0 #0003, 0 2px 4px 0 #0003, inset 0 0 0 1px #0003;
    border-radius: 5px;

    .arrow {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -1px;
        left: 190px;
        height: 25px;
        width: 20px;
        background-color: inherit;
        transform: rotate(45deg);
      }
    }

    .modal-content {
      border: none;
      border-radius: 0;
    }

    .content {
      max-height: 380px;
      overflow-y: auto;
      overflow-x: hidden;

      ul {
        padding: 0;
      }

      .list {
        background: $white;
        color: black;

        .label {
          font-size: 12px;
          font-weight: 500;
          color: #6e7f8e;
        }

        .notify {
          border-bottom: 1px solid #f3f3f3;
          width: 100%;

          .icon {
            position: relative;
            top: 2px;
            max-width: 36px;
            max-height: 36px;
            margin: 7px;
            padding: 7px;
            border: 1px solid #e2e2e2;
            border-radius: 22px;
            background-color: #faebd7;

            .alt {
              position: relative;
              top: -3px;
            }

            .jobalert {
              position: relative;
              top: -4px;
              left: -1px;
            }

            .useralert {
              position: relative;
              top: -3px;
              left: -3px;
            }
          }

          .message {
            width: 90%;

            .text {
              color: #768192;
              font-size: 11px;
            }

            .date {
              font-size: 8px;
              color: #768192;
            }
          }
        }
      }
    }

    .modal-footer {
      background: #f9f8fd;
      border: none;
      padding: 0;

      .footer-btn {
        width: 100%;
        background: #f9f8fd;
        outline: none;
        border: none;
        padding: 5px;
        color: #747f8b;
      }
    }
  }
}

.alert-message,
.label {
  width: 100%;
}

.message,
.read {
  display: inline-block;
}

.read {
  float: right;
  position: relative;
  top: 0;
  transition: 0.5s ease;
  opacity: 0;

  i {
    font-size: 1em;
    color: #dd3651;
  }
}

li:hover .read {
  opacity: 1;
}

.clear {
  font-size: 12px;
}

.dot {
  height: 2px;
  width: 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: -1px;
}
</style>
