<template>
  <div>
    <div
      class="backdrop"
      @click="isCloseEaseModal"
      v-if="isExpandSection"
    ></div>
    <div id="e-wrapper" class="cursor-pointer">
      <div
        id="ease-access"
        class="easeaccess"
        :style="[
          isExpandSection === false && isCustomerLogin
            ? { border: '2px solid #e94871' }
            : '0px',
        ]"
      >
        <div
          class="fab-btn"
          :style="[
            isExpandSection === false && isCustomerLogin
              ? { background: '#fff' }
              : '#0000004d',
          ]"
          v-if="isCustomerLogin"
        >
          <div class="fab-circle-list" v-if="isExpandSection">
            <ul>
              <li>
                <span
                  @click="isOpenAlert(), isCloseEaseModal()"
                  :class="{ highlight: getAlertMessages.length }"
                  ><span class="material-icons-round"> error_outline </span>
                  <p class="mb-0">Alert</p></span
                >
              </li>
              <li v-if="!isCandidateSupplierFromAccessToken">
                <span @click="isOpenCalender(), isCloseEaseModal()"
                  ><span class="material-icons-round"> event </span>
                  <p class="mb-0">Calender</p></span
                >
              </li>
              <li>
                <span
                  @click="isOpenEmail(), isCloseEaseModal()"
                  :class="{ highlight: getNotificationEmails.length }"
                >
                  <span class="material-icons-round"> mail_outline </span>
                  <p class="mb-0">Email</p>
                </span>
              </li>
              <li v-if="!isCandidateSupplierFromAccessToken">
                <span @click="isOpenScratch(), isCloseEaseModal()"
                  ><span class="material-icons-round"> event_note </span>
                  <p class="mb-0">Scratch Pad</p></span
                >
              </li>
            </ul>
          </div>
          <div v-else>
            <div class="fab-circle text-center" @click="isOpenEaseModal">
              <img :src="float_img" alt="floating" width="70%" />
            </div>
          </div>
        </div>
        <div class="fab-email" v-if="isSystemLogin">
          <span @click="goToEmail">
            <i class="fas fa-envelope"></i>
          </span>
        </div>
      </div>
      <Alert
        v-if="isCustomerLogin && isShowAlert"
        :isShowAlert="isShowAlert"
        @alertModalCallBack="alertModalCallBack"
      />
      <ScratchPad
        v-if="isCustomerLogin && isShowScratch"
        :isShowScratch="isShowScratch"
        @scratchModalCallBack="scratchModalCallBack"
      />
      <Calendar
        v-if="isCustomerLogin && isShowCalendar"
        :isShowPopup="isShowCalendar"
        @calendarModalCallBack="calendarModalCallBack"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import Alert from "@/containers/EaseAccess/AlertModal";
import ScratchPad from "@/containers/EaseAccess/ScratchPadModal";
import Calendar from "@/containers/EaseAccess/CalendarModal";

import float_img from "../../../public/img/new_tf_logo_icon.png";

export default {
  components: {
    Alert,
    ScratchPad,
    Calendar,
  },
  data() {
    return {
      url: null,
      Role,
      px: "px",
      oldPositionX: null,
      oldPositionY: null,
      currPositionX: null,
      currPositionY: null,
      fabElement: null,
      isExpandSection: false,
      btnPosition: null,
      wrapperElement: null,
      totalWrapperElement: null,
      addValue: null,
      minValue: null,
      headerWrapperElement: null,
      isShowAlert: false,
      isShowScratch: false,
      isShowCalendar: false,
      float_img,
    };
  },
  watch: {
    isExpandSection(value) {
      if (this.btnPosition == "left") {
        this.fabElement.style.left = value ? 115 + this.px : 30 + this.px;
      }
      if (this.btnPosition == "right") {
        let sliceWidth = this.getWindowWidth - this.wrapperElement.clientWidth;
        this.fabElement.style.left = value
          ? this.getWindowWidth - 115 - sliceWidth + this.px
          : this.getWindowWidth - 30 - sliceWidth + this.px;
      }

      // position alignment
      let sliceHeight = this.headerWrapperElement.clientHeight + 115;
      if (this.currPositionY <= sliceHeight) {
        this.addValue = sliceHeight - this.currPositionY;
        this.fabElement.style.top =
          this.currPositionY + this.addValue + this.px;
      }
      let sliceHeightY = this.totalWrapperElement.clientHeight - 165;
      if (this.currPositionY >= sliceHeightY) {
        this.minValue = this.currPositionY - sliceHeightY;
        this.fabElement.style.top =
          this.currPositionY - this.minValue + this.px;
      }
    },
    isCustomerLogin(value) {
      if (value == false && this.isExpandSection) {
        this.isExpandSection = this.fabElement.classList.toggle("fab-active");
      }
    },
    currPositionY: function (value) {
      let sliceHeightX = this.headerWrapperElement.clientHeight + 30;
      if (value <= sliceHeightX) {
        this.fabElement.style.top = sliceHeightX + this.px;
      }
      let sliceHeight = this.totalWrapperElement.clientHeight - 80;
      if (value >= sliceHeight) {
        this.fabElement.style.top = sliceHeight + this.px;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getWindowWidth",
      "getRoleScopes",
      "getNotificationEmails",
      "getAlertMessages",
      "isCandidateSupplierFromAccessToken",
    ]),
    currentUserRole() {
      const roleCheck = {
        customer_recruiter: "customer_recruiter",
        customer_admin: "customer_admin",
      };
      return this.getRoleScopes.length
        ? this.currentUserType?.code
          ? roleCheck[this.currentUserType?.code] || "customer_user"
          : this.getRoleScopes[0]
        : "";
    },
    isCustomerLogin() {
      if (
        [this.Role.customerAdmin, this.Role.customerRecruiter].includes(
          this.currentUserRole
        )
      )
        return true;
      return false;
    },
    isSystemLogin() {
      if (
        [this.Role.systemAdmin, this.Role.systemRecruiter].includes(
          this.currentUserRole
        )
      )
        return true;
      return false;
    },
  },
  methods: {
    isOpenEaseModal() {
      if (
        this.oldPositionY === this.fabElement.style.top &&
        this.oldPositionX === this.fabElement.style.left
      ) {
        this.isExpandSection = this.fabElement.classList.toggle("fab-active");
      }
    },
    isCloseEaseModal() {
      this.isExpandSection = this.fabElement.classList.toggle("fab-active");
    },
    goToEmail() {
      if (
        this.oldPositionY === this.fabElement.style.top &&
        this.oldPositionX === this.fabElement.style.left
      ) {
        this.isOpenEmail();
      }
    },
    isOpenEmail() {
      if (this.$router.currentRoute.name != "Recruiter Email Messages")
        this.$router.push("/email-messages#inbox");
    },
    isOpenAlert() {
      this.isShowAlert = true;
    },
    isOpenScratch() {
      this.isShowScratch = true;
    },
    isOpenCalender() {
      this.isShowCalendar = true;
    },
    alertModalCallBack() {
      this.isShowAlert = false;
    },
    scratchModalCallBack() {
      this.isShowScratch = false;
    },
    calendarModalCallBack() {
      this.isShowCalendar = false;
    },
  },
  created() {
    window.addEventListener("resize", () => {
      this.$store.commit("SET_WINDOW_WIDTH");
    });
  },
  mounted() {
    this.fabElement = document.getElementById("ease-access");
    this.headerWrapperElement = document.getElementById("header");
    this.totalWrapperElement = document.getElementById("app");

    const move = (e) => {
      e.preventDefault();

      if (!this.isExpandSection) {
        if (e.type === "touchmove") {
          this.fabElement.style.top = e.touches[0].clientY + this.px;
          this.fabElement.style.left = e.touches[0].clientX + this.px;
        } else {
          this.fabElement.style.top = e.clientY + this.px;
          this.fabElement.style.left = e.clientX + this.px;
        }
      }
    };

    const mouseDown = (e) => {
      this.oldPositionY = this.fabElement.style.top;
      this.oldPositionX = this.fabElement.style.left;
      if (e.type === "mousedown") {
        window.addEventListener("mousemove", move, { passive: false });
      } else {
        window.addEventListener("touchmove", move, { passive: false });
      }
      this.fabElement.style.transition = "none";
    };

    const mouseUp = (e) => {
      if (e.type === "mouseup") {
        window.removeEventListener("mousemove", move, { passive: false });
      } else {
        window.removeEventListener("touchmove", move, { passive: false });
      }
      snapToSide(e);
      this.fabElement.style.transition = "0.3s ease-in-out";
    };

    const snapToSide = (e) => {
      this.wrapperElement = document.getElementById("c-body-app");
      if (e.type === "touchend") {
        this.currPositionX = e.changedTouches[0].clientX;
        this.currPositionY = e.changedTouches[0].clientY;
      } else {
        this.currPositionX = e.clientX;
        this.currPositionY = e.clientY;
      }

      if (this.currPositionX < this.getWindowWidth / 2) {
        this.btnPosition = "left";
        this.fabElement.style.left = this.isExpandSection
          ? 115 + this.px
          : 30 + this.px;
        this.fabElement.classList.remove("right");
        this.fabElement.classList.add("left");
      } else {
        let sliceWidth = this.getWindowWidth - this.wrapperElement.clientWidth;
        this.btnPosition = "right";
        this.fabElement.style.left = this.isExpandSection
          ? this.getWindowWidth - 115 - sliceWidth + this.px
          : this.getWindowWidth - 30 - sliceWidth + this.px;
        this.fabElement.classList.remove("left");
        this.fabElement.classList.add("right");
      }
    };

    this.fabElement.addEventListener("mousedown", mouseDown);
    this.fabElement.addEventListener("mouseup", mouseUp);
    this.fabElement.addEventListener("touchstart", mouseDown);
    this.fabElement.addEventListener("touchend", mouseUp);
  },
};
</script>
<style lang="scss" scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
  // background-color: #000015;
}
#e-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;

  #ease-access {
    position: absolute;
    transform: translate(-50%, -50%);
    bottom: 40px;
    left: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1002;

    .fab-btn {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: #0000004d;
      color: white;
      z-index: 1002;
    }
    .fab-circle {
      background-color: #fff;
      // background-image: url("/img/powered_by_tf.jpeg");
      // border-radius: 50%;
      // padding: 10px;
      // box-shadow: 0 0 0 0.25rem #f5f5f566;
    }

    &.fab-active {
      background-color: #222b26c4;
      width: 200px;
      height: 200px;
      &.left {
        li:nth-child(1) {
          position: absolute;
          top: 80px;
          left: 25px;
          transition-delay: 0s;
        }
        li:nth-child(2) {
          position: absolute;
          top: 20px;
          left: 65px;
          transition-delay: 0.2s;
        }
        li:nth-child(3) {
          position: absolute;
          top: 80px;
          right: 25px;
          transition-delay: 0.4s;
        }
        li:nth-child(4) {
          position: absolute;
          bottom: 20px;
          left: 60px;
          transition-delay: 0.6s;
        }
      }
      &.right {
        li:nth-child(1) {
          position: absolute;
          top: 80px;
          right: 25px;
          transition-delay: 0s;
        }
        li:nth-child(2) {
          position: absolute;
          top: 20px;
          right: 65px;
          transition-delay: 0.2s;
        }
        li:nth-child(3) {
          position: absolute;
          top: 80px;
          left: 25px;
          transition-delay: 0.4s;
        }
        li:nth-child(4) {
          position: absolute;
          bottom: 20px;
          right: 60px;
          transition-delay: 0.6s;
        }
      }
    }
    .fab-email {
      width: 35px;
      height: 35px;
      background-color: #ff6363;
      color: #fff;
      border-radius: 10px;
      text-align: center;
      padding: 5px;
      font-size: 18px;
    }
  }
}

.easeaccess {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ul > li {
    text-align: center;
    list-style: none;
  }
  .highlight {
    color: #f44336;
  }
}
</style>
