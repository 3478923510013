<template>
  <div class="template-2">
    <div class="text-right" v-if="showDelete && template_id != null">
      <i
        class="fa-solid fa-trash-can text-danger cursor-pointer"
        @click="deleteCustomerTemplate(template_id)"
      ></i>
    </div>
    <div
      class="section d-flex align-items-center"
      :style="data.background_style || '#fff'"
    >
      <div class="row mx-0 w-100">
        <div class="col-6">
          <div class="img-container">
            <img
              width="200px"
              height="250px"
              class="img"
              :src="imageUrl(data.image_1) | convertBLOB"
            />
            <div v-if="!data.image_1" class="centered">Image 1</div>
          </div>
        </div>
        <div class="col-6">
          <h3 :class="{ 'text-primary': data.title }">
            {{ data.title || "Title" }}
          </h3>
          <p :class="{ 'text-gray': data.content }">
            {{ data.content || "Content" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { generateLocalFileUrl } from "../../helpers/helper";
export default {
  name: "Template-2",
  props: {
    data: {
      type: Object,
      default: {},
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    template_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      generateLocalFileUrl,
    };
  },
  filters: {
    convertBLOB(blob) {
      let isBase64 = null;
      if (blob) {
        // base64 pattern check
        const pattern =
          /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/;
        isBase64 = pattern.test(blob);
      }
      return blob
        ? typeof blob === "string"
          ? isBase64
            ? `data:image/jpeg;base64,${blob}`
            : blob
          : URL.createObjectURL(blob)
        : "/img/image_placeholder.png";
    },
  },
  methods: {
    ...mapActions(["deleteCustomerTemplate"]),
    imageUrl(imageUrl) {
      if (imageUrl && imageUrl.startsWith('file://')) {
        return this.generateLocalFileUrl(imageUrl);
      }
      return imageUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.section {
  min-height: 300px;
  // background-color: #fff;

  background-image: url(https://specialistlanguagecourses.com/wp-content/uploads/2021/03/BG-home-new4-1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
}
.img {
  border-radius: 100px 100px 300px 100px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12);
  object-fit: cover;
}
.img-container {
  position: relative;
  text-align: center;
  color: #202020;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
